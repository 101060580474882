<script>
    import Vue from 'vue';
    import BaseVue from '@frontend/Base.vue';
    import Gen from '../../helper/Gen';

    export default {
        extends: BaseVue,
        data() {},
        mounted() {
            this.doneProgress()
        },
        methods: {
            doneProgress(){
                this.$root.topProgress.done()
            }
        },
        watch: {},
    };
</script>

<template>
	<!-- <section id="content" class="overflow-none"> -->
		<!-- <div id="content"> -->
			<section class="section wall-bg nomargin">
				<div class="container">
					<div class="not-found">
						<div class="row justify-content-center">
							<div class="col-md-6">
								<img :src="assets('fo_images','404.png')" alt="404">
							</div>
							<div class="col-md-8">
								<h2>Oops. There's Nothing Here.</h2>
								<router-link :to="{name:'index'}" class="button button-red">Go Back Home</router-link>
							</div>
						</div>
					</div>
				</div>
			</section>
		<!-- </div> -->
	<!-- </section> -->
</template>